/* Hero Section */
.hero {
    background: linear-gradient(135deg, #1e88e5, #00acc1); /* Blue to teal gradient */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 40px 20px; /* Add padding above and below for spacing */
    text-align: center; /* Center-align text */
  }
  
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 20%, transparent 20%) 0 0,
                radial-gradient(circle, rgba(255, 255, 255, 0.1) 20%, transparent 20%) 10px 10px;
    background-size: 40px 40px;
    opacity: 0.4;
    z-index: 1;
  }
  
  .hero-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  
  .hero-button {
    padding: 12px 24px;
    font-size: 1rem;
    background-color: #00acc1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .hero-button:hover {
    background-color: #ff7043;
    box-shadow: 0 4px 15px rgba(255, 105, 180, 0.8);
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 1.8rem; /* Smaller font size for mobile */
    }
  
    .hero p {
      font-size: 1rem; /* Smaller font size for mobile */
    }
  
    .hero-button {
      font-size: 0.9rem; /* Adjust button size for mobile */
      padding: 10px 20px;
    }
  
    .hero {
      padding: 20px; /* Adjust padding for smaller screens */
    }
  }
  