/* App container */
.App {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Top navigation bar */
.App-header {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Add padding on left and right */
  background-color: #282c34;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
}

/* Logo container */
.App-logo-container {
  display: flex;
  align-items: center;
}

/* Logo styling */
.App-logo {
  height: 120px;
  width: auto;
}

/* Navigation container */
.App-nav-container {
  display: flex;
  gap: 20px; /* Space between navigation links */
  align-items: center;
  margin-left: auto; /* Keeps the navigation on the right side */
  margin-right: 40px; /* Adjust this value to control how far left the links move */
}

/* Links */
.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: 18px;
}

.App-link:hover {
  color: #21a1f1;
}

/* Main content area */
.App-content {
  margin-top: 80px; /* Offset for the fixed header */
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
}

/* Home Section */
.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 20px;
}

.home-text {
  flex: 1;
  padding: 20px;
  font-size: 1rem;
  line-height: 1.8;
}

.home-image {
  flex: 1;
  text-align: center;
}

.home-image img {
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Video Section */
.video-container {
  width: 100%; /* Ensures container spans the full width of the viewport */
  margin: 0 auto; /* Centers the video */
  overflow: hidden; /* Ensures content does not overflow */
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-video {
  width: 100%; /* Match the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensures the video crops to fit the viewport */
  display: block;
}
